<template>
    <div>
        <b-overlay :show="loading" rounded="sm" opacity="0.50" blur="blur" spinner-variant="primary">
            <b-card no-body header-bg-variant="bluenavy" header="Gestione Iscrizioni REGISTRO SPORT E SALUTE"
                header-text-variant="white" header-tag="h2" border-variant="bluenavy">

                <div class="row">
                    <div class="col-12 p-7">
                        <form>
                            <div class="form-group">
                                <label for="idannualitysodalizi">Annualità:</label>
                                <select id="idannualitysodalizi" v-model="idannualitysodalizi" class="form-control">
                                    <option value="">Seleziona un'annualità</option>
                                    <option v-for="annualitysodalizi in annualitiessodalizi"
                                        :value="annualitysodalizi.id">{{ annualitysodalizi.label }}</option>
                                </select>
                            </div>
                        </form>
                    </div>
                </div>

                <b-tabs card justified v-model="tabIndex">
                    <b-tab class="border border-primary border-top-0 rounded-bottom" title="Da Inviare"
                        :title-link-class="linkClass(0)" active>
                        <SmartTable ref="dainviare" url="/rssubscriptions/index" base_path="/rssubscriptions"
                            :fixed_filters="(typeof idannualitysodalizi !== 'undefined' && idannualitysodalizi) ? [{ name: 'sendstate', filter: 'none' }, { name: 'idannuality', filter: idannualitysodalizi }] : [{ name: 'sendstate', filter: 'none' }]"
                            :has_details="true" custom_class="mx-0 px-0">
                            <template v-slot:title>
                                <h2 class="card-title"><span
                                        class="card-label font-weight-bolder text-dark font-size-h3">Sodalizi da
                                        inviare</span></h2>
                            </template>

                            <template v-slot:before-filters>
                                <h6><i class="fas fa-file-upload text-dark-75"></i> Invio Sodalizi Selezionati</h6>

                                <div class="row py-1">
                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-primary btn-block font-weight-bolder"
                                            @click.prevent="invioLegale('dainviare')">Legale</a></div>

                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-primary btn-block font-weight-bolder"
                                            @click.prevent="invioAnagrafiche('dainviare')">Anagrafiche</a></div>

                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-primary btn-block font-weight-bolder"
                                            @click.prevent="invioConsiglio('dainviare')">Consiglio</a></div>

                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-primary btn-block font-weight-bolder"
                                            @click.prevent="invioDocumenti('dainviare')">Documenti</a></div>

                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-primary btn-block font-weight-bolder"
                                            @click.prevent="invioTesserati('dainviare')">Tesserati</a></div>

                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-primary btn-block font-weight-bolder"
                                            @click.prevent="invioAffiliazioni('dainviare')">Affiliazioni</a></div>
                                </div>

                                <div class="row py-1">
                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-primary btn-block font-weight-bolder"
                                            @click.prevent="invioConvalida('dainviare')">Convalida</a></div>

                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-warning btn-block font-weight-bolder"
                                            @click.prevent="invioFlusso('dainviare')">Flusso</a></div>

                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-secondary btn-block font-weight-bolder">Recapiti</a></div>

                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-secondary btn-block font-weight-bolder">Impianti</a></div>
                                </div>

                                <h6 class="pt-3"><i class="fas fa-file-upload text-dark-75"></i> Invio Massivo Sodalizi
                                </h6>

                                <div class="row py-1">
                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-success btn-block font-weight-bolder"
                                            @click.prevent="invioMassivoLegale('dainviare')">Legale</a></div>

                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-success btn-block font-weight-bolder"
                                            @click.prevent="invioMassivoAnagrafiche('dainviare')">Anagrafiche</a></div>

                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-success btn-block font-weight-bolder"
                                            @click.prevent="invioMassivoConsiglio('dainviare')">Consiglio</a></div>

                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-success btn-block font-weight-bolder"
                                            @click.prevent="invioMassivoDocumenti('dainviare')">Documenti</a></div>

                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-success btn-block font-weight-bolder"
                                            @click.prevent="invioMassivoTesserati('dainviare')">Tesserati</a></div>

                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-success btn-block font-weight-bolder"
                                            @click.prevent="invioMassivoAffiliazioni('dainviare')">Affiliazioni</a>
                                    </div>

                                </div>

                                <div class="row py-1 pb-3">
                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-success btn-block font-weight-bolder"
                                            @click.prevent="invioMassivoConvalida('dainviare')">Convalida</a></div>

                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-warning btn-block font-weight-bolder"
                                            @click.prevent="invioMassivoFlusso('dainviare')">Flusso</a></div>

                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-secondary btn-block font-weight-bolder">Recapiti</a></div>

                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-secondary btn-block font-weight-bolder">Impianti</a></div>
                                </div>
                            </template>

                            <template v-slot:th-rsaffiliationsendedflag="slotProps">
                                <th v-if="slotProps.values.field.is_visible_for_index" class="bg-primary text-nowrap">
                                    <span class="text-muted text-white">&nbsp;</span>
                                </th>
                            </template>

                            <template v-slot:th-hasenrolledusersflag="slotProps">
                                <th class="d-none"></th>
                            </template>

                            <template v-slot:th-idaffiliationdocumentsloadedstate="slotProps">
                                <th class="d-none"></th>
                            </template>

                            <template v-slot:th-cipflag="slotProps">
                                <th class="d-none"></th>
                            </template>

                            <template v-slot:th-idrssubscriptionstatus="slotProps">
                                <th class="d-none"></th>
                            </template>

                            <template v-slot:td-idrssubscriptionstatus="slotProps">
                                <th class="d-none"></th>
                            </template>

                            <template v-slot:td-rsaffiliationsendedflag="slotProps">
                                <td v-if="slotProps.values.field.is_visible_for_index">
                                    <div
                                        class="text-white d-flex flex-column justify-content-center align-items-start align-content-center flex-nowrap">
                                        <span v-if="slotProps.values.item.newaffiliationflag === 'Si'"
                                            class="badge pill bg-info mb-1" style="font-size: 0.9em;"><i
                                                class="fas fa-plus-square"></i> PRIMA AFFILIAZIONE</span>

                                        <span v-if="slotProps.values.item.idrssubscriptionstatus"
                                            class="badge pill bg-primary mb-1" :class="{
                                                'bg-primary': [
                                                    'In completamento',
                                                    'In convalida',
                                                    'Integrazioni inviate',
                                                    'Integrazioni inviate (dopo istruttoria)',
                                                    'Presa in carico da un operatore',
                                                    'Presa in carico da un operatore (dopo istruttoria)',
                                                    'Convalidata in istruttoria',
                                                ].includes(slotProps.values.item.idrssubscriptionstatus),

                                                'bg-warning': [
                                                    'Richiesta di integrazione',
                                                    'Richiesta di integrazione (dopo istruttoria)',
                                                ].includes(slotProps.values.item.idrssubscriptionstatus),

                                                'bg-success': [
                                                    'Domanda accolta',
                                                ].includes(slotProps.values.item.idrssubscriptionstatus),

                                                'bg-danger': [
                                                    'Iscrizione sospesa',
                                                    'Iscrizione annullata',
                                                    'Domanda rifiutata',
                                                ].includes(slotProps.values.item.idrssubscriptionstatus),
                                            }"
                                            style="font-size: 0.9em; text-transform: uppercase; text-align: left; text-wrap: wrap; max-width: 400px;"><i
                                                class="fas fa-registered text-white"></i><strong> ISCRIZIONE
                                                REGISTRO:</strong> {{ slotProps.values.item.idrssubscriptionstatus
                                            }}</span>

                                        <span v-if="slotProps.values.item.hasenrolledusersflag === 'Si'"
                                            class="badge pill bg-success mb-1" style="font-size: 0.9em;"><i
                                                class="fas fa-users text-white"></i> TESSERATI ATTIVI</span>

                                        <span v-if="slotProps.values.item.hasenrolledusersflag === 'No'"
                                            class="badge pill bg-danger mb-1" style="font-size: 0.9em;"><i
                                                class="fas fa-users-slash text-white"></i> NO TESSERATI ATTIVI</span>

                                        <span
                                            v-if="slotProps.values.item.idaffiliationdocumentsloadedstate === 'Tutti i documenti obbligatori sono stati caricati'"
                                            class="badge pill bg-success mb-1" style="font-size: 0.9em;"><i
                                                class="fas fa-file-alt text-white"></i> DOCUMENTI COMPLETI</span>

                                        <span
                                            v-if="slotProps.values.item.idaffiliationdocumentsloadedstate === 'Mancano alcuni documenti tra quelli obbligatori'"
                                            class="badge pill bg-warning mb-1" style="font-size: 0.9em;"><i
                                                class="fas fa-file-contract text-white"></i> DOCUMENTI PARZIALI</span>

                                        <span
                                            v-if="slotProps.values.item.idaffiliationdocumentsloadedstate === 'Nessun documento obbligatorio caricato'"
                                            class="badge pill bg-danger mb-1" style="font-size: 0.9em;"><i
                                                class="fas fa-file-excel text-white"></i> NESSUN DOCUMENTO</span>

                                        <span v-if="slotProps.values.item.cipflag === 'Si'" class="badge pill mb-1"
                                            style="font-size: 0.9em; background-color: #FF58F6;"><i
                                                class="fab fa-accessible-icon text-white"></i> CIP</span>

                                        <span
                                            v-if="((typeof slotProps.values.item.numsendedevents !== 'undefined') && slotProps.values.item.numsendedevents && (slotProps.values.item.numsendedevents > 0))"
                                            class="badge pill bg-success mb-1" style="font-size: 0.9em;">ATTIVITÀ
                                            TRASMESSE <span class="badge pill bg-white text-success m-0 px-2 py-1"
                                                style="font-size: 1.1em;">{{ slotProps.values.item.numsendedevents
                                                }}</span></span>

                                        <span
                                            v-if="((typeof slotProps.values.item.numsendedevents === 'undefined') || (!slotProps.values.item.numsendedevents) || (slotProps.values.item.numsendedevents <= 0))"
                                            class="badge pill bg-danger mb-1" style="font-size: 0.9em;">NESSUNA ATTIVITÀ
                                            TRASMESSA</span>
                                    </div>
                                </td>
                            </template>

                            <template v-slot:td-hasenrolledusersflag="slotProps">
                                <td class="d-none"></td>
                            </template>

                            <template v-slot:td-idaffiliationdocumentsloadedstate="slotProps">
                                <td class="d-none"></td>
                            </template>

                            <template v-slot:td-cipflag="slotProps">
                                <th class="d-none"></th>
                            </template>

                            <template v-slot:td-affiliationdocuploadedflag="slotProps">
                                <td v-if="slotProps.values.field.is_visible_for_index">
                                    <div class="d-flex align-items-center">
                                        <div class="text-white">
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'Si'"
                                                class="badge pill bg-success" style="font-size: 0.9em;">Si</span>
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'No'"
                                                class="badge pill bg-danger" style="font-size: 0.9em;">No</span>
                                        </div>
                                    </div>
                                </td>
                            </template>

                            <template v-slot:td-constitutiveactdocuploadedflag="slotProps">
                                <td v-if="slotProps.values.field.is_visible_for_index">
                                    <div class="d-flex align-items-center">
                                        <div class="text-white">
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'Si'"
                                                class="badge pill bg-success" style="font-size: 0.9em;">Si</span>
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'No'"
                                                class="badge pill bg-danger" style="font-size: 0.9em;">No</span>
                                        </div>
                                    </div>
                                </td>
                            </template>

                            <template v-slot:td-statutedocuploadedflag="slotProps">
                                <td v-if="slotProps.values.field.is_visible_for_index">
                                    <div class="d-flex align-items-center">
                                        <div class="text-white">
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'Si'"
                                                class="badge pill bg-success" style="font-size: 0.9em;">Si</span>
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'No'"
                                                class="badge pill bg-danger" style="font-size: 0.9em;">No</span>
                                        </div>
                                    </div>
                                </td>
                            </template>

                            <template v-slot:td-taxcodedocuploadedflag="slotProps">
                                <td v-if="slotProps.values.field.is_visible_for_index">
                                    <div class="d-flex align-items-center">
                                        <div class="text-white">
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'Si'"
                                                class="badge pill bg-success" style="font-size: 0.9em;">Si</span>
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'No'"
                                                class="badge pill bg-danger" style="font-size: 0.9em;">No</span>
                                        </div>
                                    </div>
                                </td>
                            </template>

                            <template v-slot:td-identitycarddocuploadedflag="slotProps">
                                <td v-if="slotProps.values.field.is_visible_for_index">
                                    <div class="d-flex align-items-center">
                                        <div class="text-white">
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'Si'"
                                                class="badge pill bg-success" style="font-size: 0.9em;">Si</span>
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'No'"
                                                class="badge pill bg-danger" style="font-size: 0.9em;">No</span>
                                        </div>
                                    </div>
                                </td>
                            </template>

                            <template v-slot:td-statutemodificationverbaldocuploadedflag="slotProps">
                                <td v-if="slotProps.values.field.is_visible_for_index">
                                    <div class="d-flex align-items-center">
                                        <div class="text-white">
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'Si'"
                                                class="badge pill bg-success" style="font-size: 0.9em;">Si</span>
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'No'"
                                                class="badge pill bg-danger" style="font-size: 0.9em;">No</span>
                                        </div>
                                    </div>
                                </td>
                            </template>

                            <template v-slot:td-boardmodificationverbaldocuploadedflag="slotProps">
                                <td v-if="slotProps.values.field.is_visible_for_index">
                                    <div class="d-flex align-items-center">
                                        <div class="text-white">
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'Si'"
                                                class="badge pill bg-success" style="font-size: 0.9em;">Si</span>
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'No'"
                                                class="badge pill bg-danger" style="font-size: 0.9em;">No</span>
                                        </div>
                                    </div>
                                </td>
                            </template>

                            <template v-slot:item-details="slotProps">
                                <ManageRsSportingClub :idsportingclub="slotProps.values.item.id"
                                    :annualityid="slotProps.values.item.annualityid" v-if="slotProps.values.item.show">
                                </ManageRsSportingClub>
                            </template>

                            <template v-slot:td-numsendedevents="slotProps">
                                <td class="d-none"></td>
                            </template>

                            <template v-slot:item-actions="slotProps">
                                <td class="text-nowrap pr-0 text-center">
                                    <a href="javascript:void(0);" class="btn btn-icon btn-circle btn-sm btn-primary"
                                        title="Gestione Sodalizio" alt="Gestione Sodalizio"
                                        @click.prevent="$router.push('/sportingclub/gestione/' + slotProps.values.item.id)">
                                        <i class="fas fa-tasks"></i>
                                    </a>
                                </td>
                            </template>
                        </SmartTable>
                    </b-tab>

                    <b-tab class="border border-primary border-top-0 rounded-bottom" title="Inviati"
                        :title-link-class="linkClass(1)">
                        <SmartTable ref="inviati" url="/rssubscriptions/index" base_path="/rssubscriptions"
                            :fixed_filters="(typeof idannualitysodalizi !== 'undefined' && idannualitysodalizi) ? [{ name: 'sendstate', filter: 'full' }, { name: 'idannuality', filter: idannualitysodalizi }] : [{ name: 'sendstate', filter: 'full' }]"
                            :has_details="true" custom_class="mx-0 px-0">
                            <template v-slot:title>
                                <h2 class="card-title"><span
                                        class="card-label font-weight-bolder text-dark font-size-h3">Sodalizi
                                        inviati</span></h2>
                            </template>

                            <template v-slot:before-filters>
                                <h6><i class="fas fa-file-upload text-dark-75"></i> Invio Sodalizi Selezionati</h6>

                                <div class="row py-1">
                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-primary btn-block font-weight-bolder"
                                            @click.prevent="invioLegale('inviati')">Legale</a></div>

                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-primary btn-block font-weight-bolder"
                                            @click.prevent="invioAnagrafiche('inviati')">Anagrafiche</a></div>

                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-primary btn-block font-weight-bolder"
                                            @click.prevent="invioConsiglio('inviati')">Consiglio</a></div>

                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-primary btn-block font-weight-bolder"
                                            @click.prevent="invioDocumenti('inviati')">Documenti</a></div>

                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-primary btn-block font-weight-bolder"
                                            @click.prevent="invioTesserati('inviati')">Tesserati</a></div>

                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-primary btn-block font-weight-bolder"
                                            @click.prevent="invioAffiliazioni('inviati')">Affiliazioni</a></div>
                                </div>

                                <div class="row py-1">
                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-primary btn-block font-weight-bolder"
                                            @click.prevent="invioConvalida('inviati')">Convalida</a></div>

                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-warning btn-block font-weight-bolder"
                                            @click.prevent="invioFlusso('inviati')">Flusso</a></div>

                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-secondary btn-block font-weight-bolder">Recapiti</a></div>

                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-secondary btn-block font-weight-bolder">Impianti</a></div>
                                </div>

                                <h6 class="pt-3"><i class="fas fa-file-upload text-dark-75"></i> Invio Massivo Sodalizi
                                </h6>

                                <div class="row py-1">
                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-success btn-block font-weight-bolder"
                                            @click.prevent="invioMassivoLegale('inviati')">Legale</a></div>

                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-success btn-block font-weight-bolder"
                                            @click.prevent="invioMassivoAnagrafiche('inviati')">Anagrafiche</a></div>

                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-success btn-block font-weight-bolder"
                                            @click.prevent="invioMassivoConsiglio('inviati')">Consiglio</a></div>

                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-success btn-block font-weight-bolder"
                                            @click.prevent="invioMassivoDocumenti('inviati')">Documenti</a></div>

                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-success btn-block font-weight-bolder"
                                            @click.prevent="invioMassivoTesserati('inviati')">Tesserati</a></div>

                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-success btn-block font-weight-bolder"
                                            @click.prevent="invioMassivoAffiliazioni('inviati')">Affiliazioni</a></div>
                                </div>

                                <div class="row py-1 pb-3">
                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-success btn-block font-weight-bolder"
                                            @click.prevent="invioMassivoConvalida('inviati')">Convalida</a></div>

                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-warning btn-block font-weight-bolder"
                                            @click.prevent="invioMassivoFlusso('inviati')">Flusso</a></div>

                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-secondary btn-block font-weight-bolder">Recapiti</a></div>

                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-secondary btn-block font-weight-bolder">Impianti</a></div>
                                </div>
                            </template>

                            <template v-slot:th-rsaffiliationsendedflag="slotProps">
                                <th v-if="slotProps.values.field.is_visible_for_index" class="bg-primary text-nowrap">
                                    <span class="text-muted text-white">&nbsp;</span>
                                </th>
                            </template>

                            <template v-slot:th-hasenrolledusersflag="slotProps">
                                <th class="d-none"></th>
                            </template>

                            <template v-slot:th-idaffiliationdocumentsloadedstate="slotProps">
                                <th class="d-none"></th>
                            </template>

                            <template v-slot:th-cipflag="slotProps">
                                <th class="d-none"></th>
                            </template>

                            <template v-slot:th-idrssubscriptionstatus="slotProps">
                                <th class="d-none"></th>
                            </template>

                            <template v-slot:td-idrssubscriptionstatus="slotProps">
                                <th class="d-none"></th>
                            </template>

                            <template v-slot:td-rsaffiliationsendedflag="slotProps">
                                <td v-if="slotProps.values.field.is_visible_for_index">
                                    <div
                                        class="text-white d-flex flex-column justify-content-center align-items-start align-content-center flex-nowrap">
                                        <span v-if="slotProps.values.item.newaffiliationflag === 'Si'"
                                            class="badge pill bg-info mb-1" style="font-size: 0.9em;"><i
                                                class="fas fa-plus-square"></i> PRIMA AFFILIAZIONE</span>

                                        <span v-if="slotProps.values.item.idrssubscriptionstatus"
                                            class="badge pill bg-primary mb-1" :class="{
                                                'bg-primary': [
                                                    'In completamento',
                                                    'In convalida',
                                                    'Integrazioni inviate',
                                                    'Integrazioni inviate (dopo istruttoria)',
                                                    'Presa in carico da un operatore',
                                                    'Presa in carico da un operatore (dopo istruttoria)',
                                                    'Convalidata in istruttoria',
                                                ].includes(slotProps.values.item.idrssubscriptionstatus),

                                                'bg-warning': [
                                                    'Richiesta di integrazione',
                                                    'Richiesta di integrazione (dopo istruttoria)',
                                                ].includes(slotProps.values.item.idrssubscriptionstatus),

                                                'bg-success': [
                                                    'Domanda accolta',
                                                ].includes(slotProps.values.item.idrssubscriptionstatus),

                                                'bg-danger': [
                                                    'Iscrizione sospesa',
                                                    'Iscrizione annullata',
                                                    'Domanda rifiutata',
                                                ].includes(slotProps.values.item.idrssubscriptionstatus),
                                            }"
                                            style="font-size: 0.9em; text-transform: uppercase; text-align: left; text-wrap: wrap; max-width: 400px;"><i
                                                class="fas fa-registered text-white"></i> <strong>ISCRIZIONE
                                                REGISTRO:</strong> {{ slotProps.values.item.idrssubscriptionstatus
                                            }}</span>

                                        <span v-if="slotProps.values.item.hasenrolledusersflag === 'Si'"
                                            class="badge pill bg-success mb-1" style="font-size: 0.9em;"><i
                                                class="fas fa-users text-white"></i> TESSERATI ATTIVI</span>

                                        <span v-if="slotProps.values.item.hasenrolledusersflag === 'No'"
                                            class="badge pill bg-danger mb-1" style="font-size: 0.9em;"><i
                                                class="fas fa-users-slash text-white"></i> NO TESSERATI ATTIVI</span>

                                        <span
                                            v-if="slotProps.values.item.idaffiliationdocumentsloadedstate === 'Tutti i documenti obbligatori sono stati caricati'"
                                            class="badge pill bg-success mb-1" style="font-size: 0.9em;"><i
                                                class="fas fa-file-alt text-white"></i> DOCUMENTI COMPLETI</span>

                                        <span
                                            v-if="slotProps.values.item.idaffiliationdocumentsloadedstate === 'Mancano alcuni documenti tra quelli obbligatori'"
                                            class="badge pill bg-warning mb-1" style="font-size: 0.9em;"><i
                                                class="fas fa-file-contract text-white"></i> DOCUMENTI PARZIALI</span>

                                        <span
                                            v-if="slotProps.values.item.idaffiliationdocumentsloadedstate === 'Nessun documento obbligatorio caricato'"
                                            class="badge pill bg-danger mb-1" style="font-size: 0.9em;"><i
                                                class="fas fa-file-excel text-white"></i> NESSUN DOCUMENTO</span>

                                        <span v-if="slotProps.values.item.cipflag === 'Si'" class="badge pill mb-1"
                                            style="font-size: 0.9em; background-color: #FF58F6;"><i
                                                class="fab fa-accessible-icon text-white"></i> CIP</span>

                                        <span
                                            v-if="((typeof slotProps.values.item.numsendedevents !== 'undefined') && slotProps.values.item.numsendedevents && (slotProps.values.item.numsendedevents > 0))"
                                            class="badge pill bg-success mb-1" style="font-size: 0.9em;">ATTIVITÀ
                                            TRASMESSE <span class="badge pill bg-white text-success m-0 px-2 py-1"
                                                style="font-size: 1.1em;">{{ slotProps.values.item.numsendedevents
                                                }}</span></span>

                                        <span
                                            v-if="((typeof slotProps.values.item.numsendedevents === 'undefined') || (!slotProps.values.item.numsendedevents) || (slotProps.values.item.numsendedevents <= 0))"
                                            class="badge pill bg-danger mb-1" style="font-size: 0.9em;">NESSUNA ATTIVITÀ
                                            TRASMESSA</span>
                                    </div>
                                </td>
                            </template>

                            <template v-slot:td-hasenrolledusersflag="slotProps">
                                <td class="d-none"></td>
                            </template>

                            <template v-slot:td-idaffiliationdocumentsloadedstate="slotProps">
                                <td class="d-none"></td>
                            </template>

                            <template v-slot:td-cipflag="slotProps">
                                <th class="d-none"></th>
                            </template>

                            <template v-slot:td-affiliationdocuploadedflag="slotProps">
                                <td v-if="slotProps.values.field.is_visible_for_index">
                                    <div class="d-flex align-items-center">
                                        <div class="text-white">
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'Si'"
                                                class="badge pill bg-success" style="font-size: 0.9em;">Si</span>
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'No'"
                                                class="badge pill bg-danger" style="font-size: 0.9em;">No</span>
                                        </div>
                                    </div>
                                </td>
                            </template>

                            <template v-slot:td-constitutiveactdocuploadedflag="slotProps">
                                <td v-if="slotProps.values.field.is_visible_for_index">
                                    <div class="d-flex align-items-center">
                                        <div class="text-white">
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'Si'"
                                                class="badge pill bg-success" style="font-size: 0.9em;">Si</span>
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'No'"
                                                class="badge pill bg-danger" style="font-size: 0.9em;">No</span>
                                        </div>
                                    </div>
                                </td>
                            </template>

                            <template v-slot:td-statutedocuploadedflag="slotProps">
                                <td v-if="slotProps.values.field.is_visible_for_index">
                                    <div class="d-flex align-items-center">
                                        <div class="text-white">
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'Si'"
                                                class="badge pill bg-success" style="font-size: 0.9em;">Si</span>
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'No'"
                                                class="badge pill bg-danger" style="font-size: 0.9em;">No</span>
                                        </div>
                                    </div>
                                </td>
                            </template>

                            <template v-slot:td-taxcodedocuploadedflag="slotProps">
                                <td v-if="slotProps.values.field.is_visible_for_index">
                                    <div class="d-flex align-items-center">
                                        <div class="text-white">
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'Si'"
                                                class="badge pill bg-success" style="font-size: 0.9em;">Si</span>
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'No'"
                                                class="badge pill bg-danger" style="font-size: 0.9em;">No</span>
                                        </div>
                                    </div>
                                </td>
                            </template>

                            <template v-slot:td-identitycarddocuploadedflag="slotProps">
                                <td v-if="slotProps.values.field.is_visible_for_index">
                                    <div class="d-flex align-items-center">
                                        <div class="text-white">
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'Si'"
                                                class="badge pill bg-success" style="font-size: 0.9em;">Si</span>
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'No'"
                                                class="badge pill bg-danger" style="font-size: 0.9em;">No</span>
                                        </div>
                                    </div>
                                </td>
                            </template>

                            <template v-slot:td-statutemodificationverbaldocuploadedflag="slotProps">
                                <td v-if="slotProps.values.field.is_visible_for_index">
                                    <div class="d-flex align-items-center">
                                        <div class="text-white">
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'Si'"
                                                class="badge pill bg-success" style="font-size: 0.9em;">Si</span>
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'No'"
                                                class="badge pill bg-danger" style="font-size: 0.9em;">No</span>
                                        </div>
                                    </div>
                                </td>
                            </template>

                            <template v-slot:td-boardmodificationverbaldocuploadedflag="slotProps">
                                <td v-if="slotProps.values.field.is_visible_for_index">
                                    <div class="d-flex align-items-center">
                                        <div class="text-white">
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'Si'"
                                                class="badge pill bg-success" style="font-size: 0.9em;">Si</span>
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'No'"
                                                class="badge pill bg-danger" style="font-size: 0.9em;">No</span>
                                        </div>
                                    </div>
                                </td>
                            </template>

                            <template v-slot:item-details="slotProps">
                                <ManageRsSportingClub :idsportingclub="slotProps.values.item.id"
                                    :annualityid="slotProps.values.item.annualityid" v-if="slotProps.values.item.show">
                                </ManageRsSportingClub>
                            </template>

                            <template v-slot:td-numsendedevents="slotProps">
                                <td class="d-none"></td>
                            </template>

                            <template v-slot:item-actions="slotProps">
                                <td class="text-nowrap pr-0 text-center">
                                    <a href="javascript:void(0);" class="btn btn-icon btn-circle btn-sm btn-primary"
                                        title="Gestione Sodalizio" alt="Gestione Sodalizio"
                                        @click.prevent="$router.push('/sportingclub/gestione/' + slotProps.values.item.id)">
                                        <i class="fas fa-tasks"></i>
                                    </a>
                                </td>
                            </template>
                        </SmartTable>
                    </b-tab>

                    <b-tab class="border border-primary border-top-0 rounded-bottom" title="Invii Parziali"
                        :title-link-class="linkClass(2)">
                        <SmartTable ref="inviiparziali" url="/rssubscriptions/index" base_path="/rssubscriptions"
                            :fixed_filters="(typeof idannualitysodalizi !== 'undefined' && idannualitysodalizi) ? [{ name: 'sendstate', filter: 'partial' }, { name: 'idannuality', filter: idannualitysodalizi }] : [{ name: 'sendstate', filter: 'partial' }]"
                            :has_details="true" custom_class="mx-0 px-0">

                            <template v-slot:title>
                                <h2 class="card-title"><span
                                        class="card-label font-weight-bolder text-dark font-size-h3">Sodalizi inviati
                                        parzialmente</span></h2>
                            </template>

                            <template v-slot:before-filters>
                                <h6><i class="fas fa-file-upload text-dark-75"></i> Invio Sodalizi Selezionati</h6>

                                <div class="row py-1">
                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-primary btn-block font-weight-bolder"
                                            @click.prevent="invioLegale('inviiparziali')">Legale</a></div>

                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-primary btn-block font-weight-bolder"
                                            @click.prevent="invioAnagrafiche('inviiparziali')">Anagrafiche</a></div>

                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-primary btn-block font-weight-bolder"
                                            @click.prevent="invioConsiglio('inviiparziali')">Consiglio</a></div>

                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-primary btn-block font-weight-bolder"
                                            @click.prevent="invioDocumenti('inviiparziali')">Documenti</a></div>

                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-primary btn-block font-weight-bolder"
                                            @click.prevent="invioTesserati('inviiparziali')">Tesserati</a></div>

                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-primary btn-block font-weight-bolder"
                                            @click.prevent="invioAffiliazioni('inviiparziali')">Affiliazioni</a></div>
                                </div>

                                <div class="row py-1">
                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-primary btn-block font-weight-bolder"
                                            @click.prevent="invioConvalida('inviiparziali')">Convalida</a></div>

                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-warning btn-block font-weight-bolder"
                                            @click.prevent="invioFlusso('inviiparziali')">Flusso</a></div>

                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-secondary btn-block font-weight-bolder">Recapiti</a></div>

                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-secondary btn-block font-weight-bolder">Impianti</a></div>
                                </div>

                                <h6 class="pt-3"><i class="fas fa-file-upload text-dark-75"></i> Invio Massivo Sodalizi
                                </h6>

                                <div class="row py-1">
                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-success btn-block font-weight-bolder"
                                            @click.prevent="invioMassivoLegale('inviiparziali')">Legale</a></div>

                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-success btn-block font-weight-bolder"
                                            @click.prevent="invioMassivoAnagrafiche('inviiparziali')">Anagrafiche</a>
                                    </div>

                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-success btn-block font-weight-bolder"
                                            @click.prevent="invioMassivoConsiglio('inviiparziali')">Consiglio</a></div>

                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-success btn-block font-weight-bolder"
                                            @click.prevent="invioMassivoDocumenti('inviiparziali')">Documenti</a></div>

                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-success btn-block font-weight-bolder"
                                            @click.prevent="invioMassivoTesserati('inviiparziali')">Tesserati</a></div>

                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-success btn-block font-weight-bolder"
                                            @click.prevent="invioMassivoAffiliazioni('inviiparziali')">Affiliazioni</a>
                                    </div>
                                </div>

                                <div class="row py-1 pb-3">
                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-success btn-block font-weight-bolder"
                                            @click.prevent="invioMassivoConvalida('inviiparziali')">Convalida</a></div>

                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-warning btn-block font-weight-bolder"
                                            @click.prevent="invioMassivoFlusso('inviiparziali')">Flusso</a></div>

                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-secondary btn-block font-weight-bolder">Recapiti</a></div>

                                    <div class="col-md-2 py-1"><a href="#"
                                            class="btn btn-secondary btn-block font-weight-bolder">Impianti</a></div>
                                </div>
                            </template>

                            <template v-slot:th-rsaffiliationsendedflag="slotProps">
                                <th v-if="slotProps.values.field.is_visible_for_index" class="bg-primary text-nowrap">
                                    <span class="text-muted text-white">&nbsp;</span>
                                </th>
                            </template>

                            <template v-slot:th-hasenrolledusersflag="slotProps">
                                <th class="d-none"></th>
                            </template>

                            <template v-slot:th-idaffiliationdocumentsloadedstate="slotProps">
                                <th class="d-none"></th>
                            </template>

                            <template v-slot:th-cipflag="slotProps">
                                <th class="d-none"></th>
                            </template>

                            <template v-slot:th-idrssubscriptionstatus="slotProps">
                                <th class="d-none"></th>
                            </template>

                            <template v-slot:td-idrssubscriptionstatus="slotProps">
                                <th class="d-none"></th>
                            </template>

                            <template v-slot:td-rsaffiliationsendedflag="slotProps">
                                <td v-if="slotProps.values.field.is_visible_for_index">
                                    <div
                                        class="text-white d-flex flex-column justify-content-center align-items-start align-content-center flex-nowrap">
                                        <span v-if="slotProps.values.item.newaffiliationflag === 'Si'"
                                            class="badge pill bg-info mb-1" style="font-size: 0.9em;"><i
                                                class="fas fa-plus-square"></i> PRIMA AFFILIAZIONE</span>

                                        <span v-if="slotProps.values.item.idrssubscriptionstatus"
                                            class="badge pill bg-primary mb-1" :class="{
                                                'bg-primary': [
                                                    'In completamento',
                                                    'In convalida',
                                                    'Integrazioni inviate',
                                                    'Integrazioni inviate (dopo istruttoria)',
                                                    'Presa in carico da un operatore',
                                                    'Presa in carico da un operatore (dopo istruttoria)',
                                                    'Convalidata in istruttoria',
                                                ].includes(slotProps.values.item.idrssubscriptionstatus),

                                                'bg-warning': [
                                                    'Richiesta di integrazione',
                                                    'Richiesta di integrazione (dopo istruttoria)',
                                                ].includes(slotProps.values.item.idrssubscriptionstatus),

                                                'bg-success': [
                                                    'Domanda accolta',
                                                ].includes(slotProps.values.item.idrssubscriptionstatus),

                                                'bg-danger': [
                                                    'Iscrizione sospesa',
                                                    'Iscrizione annullata',
                                                    'Domanda rifiutata',
                                                ].includes(slotProps.values.item.idrssubscriptionstatus),
                                            }"
                                            style="font-size: 0.9em; text-transform: uppercase; text-align: left; text-wrap: wrap; max-width: 400px;"><i
                                                class="fas fa-registered text-white"></i> <strong>ISCRIZIONE
                                                REGISTRO:</strong> {{ slotProps.values.item.idrssubscriptionstatus
                                            }}</span>

                                        <span v-if="slotProps.values.item.hasenrolledusersflag === 'Si'"
                                            class="badge pill bg-success mb-1" style="font-size: 0.9em;"><i
                                                class="fas fa-users text-white"></i> TESSERATI ATTIVI</span>

                                        <span v-if="slotProps.values.item.hasenrolledusersflag === 'No'"
                                            class="badge pill bg-danger mb-1" style="font-size: 0.9em;"><i
                                                class="fas fa-users-slash text-white"></i> NO TESSERATI ATTIVI</span>

                                        <span
                                            v-if="slotProps.values.item.idaffiliationdocumentsloadedstate === 'Tutti i documenti obbligatori sono stati caricati'"
                                            class="badge pill bg-success mb-1" style="font-size: 0.9em;"><i
                                                class="fas fa-file-alt text-white"></i> DOCUMENTI COMPLETI</span>

                                        <span
                                            v-if="slotProps.values.item.idaffiliationdocumentsloadedstate === 'Mancano alcuni documenti tra quelli obbligatori'"
                                            class="badge pill bg-warning mb-1" style="font-size: 0.9em;"><i
                                                class="fas fa-file-contract text-white"></i> DOCUMENTI PARZIALI</span>

                                        <span
                                            v-if="slotProps.values.item.idaffiliationdocumentsloadedstate === 'Nessun documento obbligatorio caricato'"
                                            class="badge pill bg-danger mb-1" style="font-size: 0.9em;"><i
                                                class="fas fa-file-excel text-white"></i> NESSUN DOCUMENTO</span>

                                        <span v-if="slotProps.values.item.cipflag === 'Si'" class="badge pill mb-1"
                                            style="font-size: 0.9em; background-color: #FF58F6;"><i
                                                class="fab fa-accessible-icon text-white"></i> CIP</span>

                                        <span
                                            v-if="((slotProps.values.item.coniaffiliationflag === 'Si' && slotProps.values.item.coniaffiliationflagadmin === 'No') && (typeof slotProps.values.item.numsendedevents !== 'undefined') && slotProps.values.item.numsendedevents && (slotProps.values.item.numsendedevents > 0))"
                                            class="badge pill bg-success mb-1" style="font-size: 0.9em;">ATTIVITÀ
                                            TRASMESSE <span class="badge pill bg-white text-success m-0 px-2 py-1"
                                                style="font-size: 1.1em;">{{ slotProps.values.item.numsendedevents
                                                }}</span></span>

                                        <span
                                            v-if="((slotProps.values.item.coniaffiliationflag === 'Si' && slotProps.values.item.coniaffiliationflagadmin === 'No') && ((typeof slotProps.values.item.numsendedevents === 'undefined') || (!slotProps.values.item.numsendedevents) || (slotProps.values.item.numsendedevents <= 0)))"
                                            class="badge pill bg-danger mb-1" style="font-size: 0.9em;">NESSUNA ATTIVITÀ
                                            TRASMESSA</span>
                                    </div>
                                </td>
                            </template>

                            <template v-slot:td-hasenrolledusersflag="slotProps">
                                <td class="d-none"></td>
                            </template>

                            <template v-slot:td-idaffiliationdocumentsloadedstate="slotProps">
                                <td class="d-none"></td>
                            </template>

                            <template v-slot:td-cipflag="slotProps">
                                <th class="d-none"></th>
                            </template>

                            <template v-slot:td-affiliationdocuploadedflag="slotProps">
                                <td v-if="slotProps.values.field.is_visible_for_index">
                                    <div class="d-flex align-items-center">
                                        <div class="text-white">
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'Si'"
                                                class="badge pill bg-success" style="font-size: 0.9em;">Si</span>
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'No'"
                                                class="badge pill bg-danger" style="font-size: 0.9em;">No</span>
                                        </div>
                                    </div>
                                </td>
                            </template>

                            <template v-slot:td-constitutiveactdocuploadedflag="slotProps">
                                <td v-if="slotProps.values.field.is_visible_for_index">
                                    <div class="d-flex align-items-center">
                                        <div class="text-white">
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'Si'"
                                                class="badge pill bg-success" style="font-size: 0.9em;">Si</span>
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'No'"
                                                class="badge pill bg-danger" style="font-size: 0.9em;">No</span>
                                        </div>
                                    </div>
                                </td>
                            </template>

                            <template v-slot:td-statutedocuploadedflag="slotProps">
                                <td v-if="slotProps.values.field.is_visible_for_index">
                                    <div class="d-flex align-items-center">
                                        <div class="text-white">
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'Si'"
                                                class="badge pill bg-success" style="font-size: 0.9em;">Si</span>
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'No'"
                                                class="badge pill bg-danger" style="font-size: 0.9em;">No</span>
                                        </div>
                                    </div>
                                </td>
                            </template>

                            <template v-slot:td-taxcodedocuploadedflag="slotProps">
                                <td v-if="slotProps.values.field.is_visible_for_index">
                                    <div class="d-flex align-items-center">
                                        <div class="text-white">
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'Si'"
                                                class="badge pill bg-success" style="font-size: 0.9em;">Si</span>
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'No'"
                                                class="badge pill bg-danger" style="font-size: 0.9em;">No</span>
                                        </div>
                                    </div>
                                </td>
                            </template>

                            <template v-slot:td-identitycarddocuploadedflag="slotProps">
                                <td v-if="slotProps.values.field.is_visible_for_index">
                                    <div class="d-flex align-items-center">
                                        <div class="text-white">
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'Si'"
                                                class="badge pill bg-success" style="font-size: 0.9em;">Si</span>
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'No'"
                                                class="badge pill bg-danger" style="font-size: 0.9em;">No</span>
                                        </div>
                                    </div>
                                </td>
                            </template>

                            <template v-slot:td-statutemodificationverbaldocuploadedflag="slotProps">
                                <td v-if="slotProps.values.field.is_visible_for_index">
                                    <div class="d-flex align-items-center">
                                        <div class="text-white">
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'Si'"
                                                class="badge pill bg-success" style="font-size: 0.9em;">Si</span>
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'No'"
                                                class="badge pill bg-danger" style="font-size: 0.9em;">No</span>
                                        </div>
                                    </div>
                                </td>
                            </template>

                            <template v-slot:td-boardmodificationverbaldocuploadedflag="slotProps">
                                <td v-if="slotProps.values.field.is_visible_for_index">
                                    <div class="d-flex align-items-center">
                                        <div class="text-white">
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'Si'"
                                                class="badge pill bg-success" style="font-size: 0.9em;">Si</span>
                                            <span v-if="slotProps.values.item[slotProps.values.field.name] === 'No'"
                                                class="badge pill bg-danger" style="font-size: 0.9em;">No</span>
                                        </div>
                                    </div>
                                </td>
                            </template>

                            <template v-slot:item-details="slotProps">
                                <ManageRsSportingClub :idsportingclub="slotProps.values.item.id"
                                    :annualityid="slotProps.values.item.annualityid" :item="slotProps.values.item"
                                    v-if="slotProps.values.item.show">
                                </ManageRsSportingClub>
                            </template>

                            <template v-slot:td-numsendedevents="slotProps">
                                <td class="d-none"></td>
                            </template>

                            <template v-slot:item-actions="slotProps">
                                <td class="text-nowrap pr-0 text-center">
                                    <a href="javascript:void(0);" class="btn btn-icon btn-circle btn-sm btn-primary"
                                        title="Gestione Sodalizio" alt="Gestione Sodalizio"
                                        @click.prevent="$router.push('/sportingclub/gestione/' + slotProps.values.item.id)">
                                        <i class="fas fa-tasks"></i>
                                    </a>
                                </td>
                            </template>
                        </SmartTable>
                    </b-tab>

                    <b-tab class="border border-primary border-top-0 rounded-bottom" title="Monitoraggio"
                        :title-link-class="linkClass(3)">
                        <SmartTable ref="monitoraggio" url="/rsmonitoring/index" :is_dialog="true"
                            base_url="/rsmonitoring" base_path="/"
                            :fixed_filters="(typeof idannualitysodalizi !== 'undefined' && idannualitysodalizi) ? [{ name: 'idannuality', filter: idannualitysodalizi }] : []"
                            custom_class="mx-0 px-0" v-on:close-modal="closeModal">

                            <template v-slot:title>
                                <h2 class="card-title"><span
                                        class="card-label font-weight-bolder text-dark font-size-h3">Monitoraggio
                                        Invii</span></h2>
                            </template>

                            <template v-slot:td-step="slotProps">
                                <td>
                                    <div class="d-flex align-items-center flex-column">
                                        <div>
                                            <span class="text-dark-75 text-hover-primary mb-1 font-size-lg">{{
                                                slotProps.values.item.step }}</span>
                                        </div>

                                        <div v-if="(typeof slotProps.values.item.step !== 'undefined') && (slotProps.values.item.step === 'INVIA_DOCUMENTI') && (typeof slotProps.values.item.objectparam !== 'undefined') && (slotProps.values.item.objectparam instanceof Array) && (slotProps.values.item.objectparam.length)"
                                            class="d-flex align-items-center">
                                            <span v-for="documento in slotProps.values.item.objectparam"
                                                class="label font-weight-bold label-inline mr-1 mt-1 label-primary"
                                                style="font-size: 1rem; padding: .3rem 1rem; height: auto !important;">{{
                                                    documento }}</span>
                                        </div>
                                    </div>
                                </td>
                            </template>

                            <template v-slot:item-actions="slotProps">
                                <td class="text-nowrap pr-0 text-center">
                                    <a v-if="!slotProps.values.item.errormessage" href="javascript:void(0);"
                                        class="btn btn-icon btn-circle btn-sm btn-success mr-3" title="Dettagli"
                                        alt="Dettagli"
                                        @click.prevent="slotProps.values.doItemAction(slotProps.values.item.actions[0], slotProps.values.item.id)">
                                        <i class="fas fa-eye"></i>
                                    </a>

                                    <a v-if="slotProps.values.item.errormessage" href="javascript:void(0);"
                                        class="btn btn-icon btn-circle btn-sm btn-danger mr-3" title="Dettagli"
                                        alt="Dettagli"
                                        @click.prevent="slotProps.values.doItemAction(slotProps.values.item.actions[0], slotProps.values.item.id)">
                                        <i class="fas fa-exclamation-triangle"></i>
                                    </a>
                                </td>
                            </template>
                        </SmartTable>
                    </b-tab>
                </b-tabs>
            </b-card>
        </b-overlay>
    </div>
</template>

<script>
import SmartTable from "@/view/components/SmartTable.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from 'sweetalert2';
import ApiService from "@/core/services/api.service";

export default {
    data() {
        return {
            tabIndex: 0,
            loading: false,

            idannualitysodalizi: '',
            annualitiessodalizi: [],
        };
    },

    components: {
        SmartTable,
        ManageRsSportingClub: () => import("@/view/components/ManageRsSportingClub.vue"),
    },

    created() {
        let url = '/rssubscriptions/load-options?type=annualities';

        ApiService.query(url)
            .then((response) => {
                this.annualitiessodalizi = response.data;

                if (this.annualitiessodalizi.length) {
                    for (let i in this.annualitiessodalizi) {
                        if (this.annualitiessodalizi[i].selected) this.idannualitysodalizi = this.annualitiessodalizi[i].id;
                    }
                }

                if (!this.idannualitysodalizi) this.idannualitysodalizi = this.annualitiessodalizi[0].id;
            })
            .catch((error) => {
                console.log(error);
            })
    },

    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Affiliazioni", route: "/sportingclub/gestione-iscrizioni-registro-sport-e-salute" },
            { title: "Gestione Iscrizioni REGISTRO SPORT E SALUTE" }
        ]);
    },

    watch: {
        idannualitysodalizi(value) {
            let vm = this;
            setTimeout(function () {
                vm.$refs.dainviare.refresh();
                vm.$refs.inviiparziali.refresh();
                vm.$refs.inviati.refresh();
                vm.$refs.monitoraggio.refresh();
            }, 100);
        },
    },

    methods: {
        linkClass(idx) {
            if (this.tabIndex === idx) {
                return ['text-primary', 'border-top-primary', 'border-left-primary', 'border-right-primary', 'text-center', 'font-size-h5']
            } else {
                return ['bg-primary', 'text-white', 'font-size-h5']
            }
        },

        closeModal() {
        },

        invioLegale(table) {
            Swal.fire({
                title: 'Conferma Invio Legale',
                text: "Sei sicuro di voler inviare i Legali Rappresentati dei Sodalizi selezionati?",
                icon: 'success',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let values = [];
                    for (let i in this.$refs[table].items) {
                        if (this.$refs[table].items[i].checked) values.push({ idsportingclub: this.$refs[table].items[i].id, idannuality: this.$refs[table].items[i].annualityid });
                    }

                    if (values.length) {
                        let url = '/rsmonitoring/send-selected-legal';
                        let data = { values: values };

                        ApiService.post(url, data)
                            .then((response) => {
                                if (response.data.status === 'OK') {
                                    this.$bvToast.toast('Invio messo correttamente in coda', {
                                        title: 'Invio messo correttamente in coda',
                                        variant: 'success',
                                        solid: true
                                    });

                                    this.$refs.monitoraggio.refresh();
                                }

                                if (response.data.status === 'KO') {
                                    this.$bvToast.toast('Si sono verificati degli errori nella messa in coda dell\'invio.', {
                                        title: 'Errore nella messa in coda dell\'invio',
                                        variant: 'danger',
                                        solid: true
                                    });
                                }
                            })
                            .catch(({ response }) => {
                                console.log(response);
                            });
                    }
                    else {
                        Swal.fire({
                            title: 'Nessun Sodalizio selezionato',
                            text: "Per effettuare un'operazione selezionare almeno un Sodalizio",
                            icon: 'warning',
                            showConfirmButton: true,
                            confirmButtonText: 'Ok',
                        });
                    }

                    this.loading = false;
                }
            });
        },

        invioConvalida(table) {
            Swal.fire({
                title: 'Conferma Invio Convalida',
                text: "Sei sicuro di voler inviare le Convalide dei Sodalizi selezionati?",
                icon: 'success',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let values = [];
                    for (let i in this.$refs[table].items) {
                        if (this.$refs[table].items[i].checked) values.push({ idsportingclub: this.$refs[table].items[i].id, idannuality: this.$refs[table].items[i].annualityid });
                    }

                    if (values.length) {
                        let url = '/rsmonitoring/send-selected-validation';
                        let data = { values: values };

                        ApiService.post(url, data)
                            .then((response) => {
                                if (response.data.status === 'OK') {
                                    this.$bvToast.toast('Invio messo correttamente in coda', {
                                        title: 'Invio messo correttamente in coda',
                                        variant: 'success',
                                        solid: true
                                    });

                                    this.$refs.monitoraggio.refresh();
                                }

                                if (response.data.status === 'KO') {
                                    this.$bvToast.toast('Si sono verificati degli errori nella messa in coda dell\'invio.', {
                                        title: 'Errore nella messa in coda dell\'invio',
                                        variant: 'danger',
                                        solid: true
                                    });
                                }
                            })
                            .catch(({ response }) => {
                                console.log(response);
                            });
                    }
                    else {
                        Swal.fire({
                            title: 'Nessun Sodalizio selezionato',
                            text: "Per effettuare un'operazione selezionare almeno un Sodalizio",
                            icon: 'warning',
                            showConfirmButton: true,
                            confirmButtonText: 'Ok',
                        });
                    }

                    this.loading = false;
                }
            });
        },

        invioAnagrafiche(table) {
            Swal.fire({
                title: 'Conferma Invio Anagrafiche',
                text: "Sei sicuro di voler inviare le Anagrafiche dei Sodalizi selezionati?",
                icon: 'success',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let values = [];
                    for (let i in this.$refs[table].items) {
                        if (this.$refs[table].items[i].checked) values.push({ idsportingclub: this.$refs[table].items[i].id, idannuality: this.$refs[table].items[i].annualityid });
                    }

                    if (values.length) {
                        let url = '/rsmonitoring/send-selected-sportingclubs';
                        let data = { values: values };

                        ApiService.post(url, data)
                            .then((response) => {
                                if (response.data.status === 'OK') {
                                    this.$bvToast.toast('Invio messo correttamente in coda', {
                                        title: 'Invio messo correttamente in coda',
                                        variant: 'success',
                                        solid: true
                                    });

                                    this.$refs.monitoraggio.refresh();
                                }

                                if (response.data.status === 'KO') {
                                    this.$bvToast.toast('Si sono verificati degli errori nella messa in coda dell\'invio.', {
                                        title: 'Errore nella messa in coda dell\'invio',
                                        variant: 'danger',
                                        solid: true
                                    });
                                }
                            })
                            .catch(({ response }) => {
                                console.log(response);
                            });
                    }
                    else {
                        Swal.fire({
                            title: 'Nessun Sodalizio selezionato',
                            text: "Per effettuare un'operazione selezionare almeno un Sodalizio",
                            icon: 'warning',
                            showConfirmButton: true,
                            confirmButtonText: 'Ok',
                        });
                    }

                    this.loading = false;
                }
            });
        },

        invioMassivoConvalida(table) {
            Swal.fire({
                title: 'Conferma Invio Massivo Convalida',
                text: "Sei sicuro di voler inviare la Convalida massivamente?",
                icon: 'success',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let url = '/rsmonitoring/send-massive-validation';

                    let smartTable = this.$refs[table];
                    let filterQueryString = smartTable.getFiltersQueryString();
                    if (filterQueryString) url += '?' + filterQueryString;

                    let data = { type: table };

                    ApiService.post(url, data)
                        .then((response) => {
                            if (response.data.status === 'OK') {
                                this.$bvToast.toast('Invio messo correttamente in coda', {
                                    title: 'Invio messo correttamente in coda',
                                    variant: 'success',
                                    solid: true
                                });

                                this.$refs.monitoraggio.refresh();
                            }

                            if (response.data.status === 'KO') {
                                this.$bvToast.toast('Si sono verificati degli errori nella messa in coda dell\'invio.', {
                                    title: 'Errore nella messa in coda dell\'invio',
                                    variant: 'danger',
                                    solid: true
                                });
                            }
                        })
                        .catch(({ response }) => {
                            console.log(response);
                        });

                    this.loading = false;
                }
            });
        },

        invioMassivoLegale(table) {
            Swal.fire({
                title: 'Conferma Invio Massivo Legale Rappresentate',
                text: "Sei sicuro di voler inviare il Legale Rappresentante massivamente?",
                icon: 'success',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let url = '/rsmonitoring/send-massive-legal';

                    let smartTable = this.$refs[table];
                    let filterQueryString = smartTable.getFiltersQueryString();
                    if (filterQueryString) url += '?' + filterQueryString;

                    let data = { type: table };

                    ApiService.post(url, data)
                        .then((response) => {
                            if (response.data.status === 'OK') {
                                this.$bvToast.toast('Invio messo correttamente in coda', {
                                    title: 'Invio messo correttamente in coda',
                                    variant: 'success',
                                    solid: true
                                });

                                this.$refs.monitoraggio.refresh();
                            }

                            if (response.data.status === 'KO') {
                                this.$bvToast.toast('Si sono verificati degli errori nella messa in coda dell\'invio.', {
                                    title: 'Errore nella messa in coda dell\'invio',
                                    variant: 'danger',
                                    solid: true
                                });
                            }
                        })
                        .catch(({ response }) => {
                            console.log(response);
                        });

                    this.loading = false;
                }
            });
        },

        invioMassivoAnagrafiche(table) {
            Swal.fire({
                title: 'Conferma Invio Massivo Anagrafiche',
                text: "Sei sicuro di voler inviare le Anagrafiche massivamente?",
                icon: 'success',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let url = '/rsmonitoring/send-massive-sportingclubs';

                    let smartTable = this.$refs[table];
                    let filterQueryString = smartTable.getFiltersQueryString();
                    if (filterQueryString) url += '?' + filterQueryString;

                    let data = { type: table };

                    ApiService.post(url, data)
                        .then((response) => {
                            if (response.data.status === 'OK') {
                                this.$bvToast.toast('Invio messo correttamente in coda', {
                                    title: 'Invio messo correttamente in coda',
                                    variant: 'success',
                                    solid: true
                                });

                                this.$refs.monitoraggio.refresh();
                            }

                            if (response.data.status === 'KO') {
                                this.$bvToast.toast('Si sono verificati degli errori nella messa in coda dell\'invio.', {
                                    title: 'Errore nella messa in coda dell\'invio',
                                    variant: 'danger',
                                    solid: true
                                });
                            }
                        })
                        .catch(({ response }) => {
                            console.log(response);
                        });

                    this.loading = false;
                }
            });
        },

        invioAffiliazioni(table) {
            Swal.fire({
                title: 'Conferma Invio Affiliazioni',
                text: "Sei sicuro di voler inviare le Affiliazioni dei Sodalizi selezionati?",
                icon: 'success',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let values = [];
                    for (let i in this.$refs[table].items) {
                        if (this.$refs[table].items[i].checked) values.push({ idsportingclub: this.$refs[table].items[i].id, idannuality: this.$refs[table].items[i].annualityid });
                    }

                    if (values.length) {
                        let url = '/rsmonitoring/send-selected-affiliations';
                        let data = { values: values };

                        ApiService.post(url, data)
                            .then((response) => {
                                if (response.data.status === 'OK') {
                                    this.$bvToast.toast('Invio messo correttamente in coda', {
                                        title: 'Invio messo correttamente in coda',
                                        variant: 'success',
                                        solid: true
                                    });

                                    this.$refs.monitoraggio.refresh();
                                }

                                if (response.data.status === 'KO') {
                                    this.$bvToast.toast('Si sono verificati degli errori nella messa in coda dell\'invio.', {
                                        title: 'Errore nella messa in coda dell\'invio',
                                        variant: 'danger',
                                        solid: true
                                    });
                                }
                            })
                            .catch(({ response }) => {
                                console.log(response);
                            });
                    }
                    else {
                        Swal.fire({
                            title: 'Nessun Sodalizio selezionato',
                            text: "Per effettuare un'operazione selezionare almeno un Sodalizio",
                            icon: 'warning',
                            showConfirmButton: true,
                            confirmButtonText: 'Ok',
                        });
                    }

                    this.loading = false;
                }
            });
        },

        invioMassivoAffiliazioni(table) {
            Swal.fire({
                title: 'Conferma Invio Massivo Affiliazioni',
                text: "Sei sicuro di voler inviare le Affiliazioni massivamente?",
                icon: 'success',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let url = '/rsmonitoring/send-massive-affiliations';

                    let smartTable = this.$refs[table];
                    let filterQueryString = smartTable.getFiltersQueryString();
                    if (filterQueryString) url += '?' + filterQueryString;

                    let data = { type: table };

                    ApiService.post(url, data)
                        .then((response) => {
                            if (response.data.status === 'OK') {
                                this.$bvToast.toast('Invio messo correttamente in coda', {
                                    title: 'Invio messo correttamente in coda',
                                    variant: 'success',
                                    solid: true
                                });

                                this.$refs.monitoraggio.refresh();
                            }

                            if (response.data.status === 'KO') {
                                this.$bvToast.toast('Si sono verificati degli errori nella messa in coda dell\'invio.', {
                                    title: 'Errore nella messa in coda dell\'invio',
                                    variant: 'danger',
                                    solid: true
                                });
                            }
                        })
                        .catch(({ response }) => {
                            console.log(response);
                        });

                    this.loading = false;
                }
            });
        },

        invioRecapiti(table) {
            Swal.fire({
                title: 'Conferma Invio Recapiti',
                text: "Sei sicuro di voler i Recapiti dei Sodalizi selezionati?",
                icon: 'success',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let values = [];
                    for (let i in this.$refs[table].items) {
                        if (this.$refs[table].items[i].checked) values.push({ idsportingclub: this.$refs[table].items[i].id, idannuality: this.$refs[table].items[i].annualityid });
                    }

                    if (values.length) {
                        let url = '/rsmonitoring/send-selected-details';
                        let data = { values: values };

                        ApiService.post(url, data)
                            .then((response) => {
                                if (response.data.status === 'OK') {
                                    this.$bvToast.toast('Invio messo correttamente in coda', {
                                        title: 'Invio messo correttamente in coda',
                                        variant: 'success',
                                        solid: true
                                    });

                                    this.$refs.monitoraggio.refresh();
                                }

                                if (response.data.status === 'KO') {
                                    this.$bvToast.toast('Si sono verificati degli errori nella messa in coda dell\'invio.', {
                                        title: 'Errore nella messa in coda dell\'invio',
                                        variant: 'danger',
                                        solid: true
                                    });
                                }
                            })
                            .catch(({ response }) => {
                                console.log(response);
                            });
                    }
                    else {
                        Swal.fire({
                            title: 'Nessun Sodalizio selezionato',
                            text: "Per effettuare un'operazione selezionare almeno un Sodalizio",
                            icon: 'warning',
                            showConfirmButton: true,
                            confirmButtonText: 'Ok',
                        });
                    }

                    this.loading = false;
                }
            })
        },

        invioMassivoRecapiti(table) {
            Swal.fire({
                title: 'Conferma Invio Massivo Recapiti',
                text: "Sei sicuro di voler i Recapiti massivamente?",
                icon: 'success',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let url = '/rsmonitoring/send-massive-details';

                    let smartTable = this.$refs[table];
                    let filterQueryString = smartTable.getFiltersQueryString();
                    if (filterQueryString) url += '?' + filterQueryString;

                    let data = { type: table };

                    ApiService.post(url, data)
                        .then((response) => {
                            if (response.data.status === 'OK') {
                                this.$bvToast.toast('Invio messo correttamente in coda', {
                                    title: 'Invio messo correttamente in coda',
                                    variant: 'success',
                                    solid: true
                                });

                                this.$refs.monitoraggio.refresh();
                            }

                            if (response.data.status === 'KO') {
                                this.$bvToast.toast('Si sono verificati degli errori nella messa in coda dell\'invio.', {
                                    title: 'Errore nella messa in coda dell\'invio',
                                    variant: 'danger',
                                    solid: true
                                });
                            }
                        })
                        .catch(({ response }) => {
                            console.log(response);
                        });

                    this.loading = false;
                }
            })
        },

        invioImpianti(table) {
            Swal.fire({
                title: 'Conferma Invio Impianti',
                text: "Sei sicuro di voler gli Impianti dei Sodalizi selezionati?",
                icon: 'success',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let values = [];
                    for (let i in this.$refs[table].items) {
                        if (this.$refs[table].items[i].checked) values.push({ idsportingclub: this.$refs[table].items[i].id, idannuality: this.$refs[table].items[i].annualityid });
                    }

                    if (values.length) {
                        let url = '/rsmonitoring/send-selected-facilities';
                        let data = { values: values };

                        ApiService.post(url, data)
                            .then((response) => {
                                if (response.data.status === 'OK') {
                                    this.$bvToast.toast('Invio messo correttamente in coda', {
                                        title: 'Invio messo correttamente in coda',
                                        variant: 'success',
                                        solid: true
                                    });

                                    this.$refs.monitoraggio.refresh();
                                }

                                if (response.data.status === 'KO') {
                                    this.$bvToast.toast('Si sono verificati degli errori nella messa in coda dell\'invio.', {
                                        title: 'Errore nella messa in coda dell\'invio',
                                        variant: 'danger',
                                        solid: true
                                    });
                                }
                            })
                            .catch(({ response }) => {
                                console.log(response);
                            });
                    }
                    else {
                        Swal.fire({
                            title: 'Nessun Sodalizio selezionato',
                            text: "Per effettuare un'operazione selezionare almeno un Sodalizio",
                            icon: 'warning',
                            showConfirmButton: true,
                            confirmButtonText: 'Ok',
                        });
                    }

                    this.loading = false;
                }
            })
        },

        invioMassivoImpianti(table) {
            Swal.fire({
                title: 'Conferma Invio Massivo Impianti',
                text: "Sei sicuro di voler gli Impianti massivamente?",
                icon: 'success',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let url = '/rsmonitoring/send-massive-facilities';

                    let smartTable = this.$refs[table];
                    let filterQueryString = smartTable.getFiltersQueryString();
                    if (filterQueryString) url += '?' + filterQueryString;

                    let data = { type: table };

                    ApiService.post(url, data)
                        .then((response) => {
                            if (response.data.status === 'OK') {
                                this.$bvToast.toast('Invio messo correttamente in coda', {
                                    title: 'Invio messo correttamente in coda',
                                    variant: 'success',
                                    solid: true
                                });

                                this.$refs.monitoraggio.refresh();
                            }

                            if (response.data.status === 'KO') {
                                this.$bvToast.toast('Si sono verificati degli errori nella messa in coda dell\'invio.', {
                                    title: 'Errore nella messa in coda dell\'invio',
                                    variant: 'danger',
                                    solid: true
                                });
                            }
                        })
                        .catch(({ response }) => {
                            console.log(response);
                        });

                    this.loading = false;
                }
            })
        },

        invioConsiglio(table) {
            Swal.fire({
                title: 'Conferma Invio Consiglio',
                text: "Sei sicuro di voler il Consiglio dei Sodalizi selezionati?",
                icon: 'success',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let values = [];
                    for (let i in this.$refs[table].items) {
                        if (this.$refs[table].items[i].checked) values.push({ idsportingclub: this.$refs[table].items[i].id, idannuality: this.$refs[table].items[i].annualityid });
                    }

                    if (values.length) {
                        let url = '/rsmonitoring/send-selected-council';
                        let data = { values: values };

                        ApiService.post(url, data)
                            .then((response) => {
                                if (response.data.status === 'OK') {
                                    this.$bvToast.toast('Invio messo correttamente in coda', {
                                        title: 'Invio messo correttamente in coda',
                                        variant: 'success',
                                        solid: true
                                    });

                                    this.$refs.monitoraggio.refresh();
                                }

                                if (response.data.status === 'KO') {
                                    this.$bvToast.toast('Si sono verificati degli errori nella messa in coda dell\'invio.', {
                                        title: 'Errore nella messa in coda dell\'invio',
                                        variant: 'danger',
                                        solid: true
                                    });
                                }
                            })
                            .catch(({ response }) => {
                                console.log(response);
                            });
                    }
                    else {
                        Swal.fire({
                            title: 'Nessun Sodalizio selezionato',
                            text: "Per effettuare un'operazione selezionare almeno un Sodalizio",
                            icon: 'warning',
                            showConfirmButton: true,
                            confirmButtonText: 'Ok',
                        });
                    }

                    this.loading = false;
                }
            })
        },

        invioMassivoConsiglio(table) {
            Swal.fire({
                title: 'Conferma Invio Massivo Consiglio',
                text: "Sei sicuro di voler il Consiglio massivamente?",
                icon: 'success',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let url = '/rsmonitoring/send-massive-council';

                    let smartTable = this.$refs[table];
                    let filterQueryString = smartTable.getFiltersQueryString();
                    if (filterQueryString) url += '?' + filterQueryString;

                    let data = { type: table };

                    ApiService.post(url, data)
                        .then((response) => {
                            if (response.data.status === 'OK') {
                                this.$bvToast.toast('Invio messo correttamente in coda', {
                                    title: 'Invio messo correttamente in coda',
                                    variant: 'success',
                                    solid: true
                                });

                                this.$refs.monitoraggio.refresh();
                            }

                            if (response.data.status === 'KO') {
                                this.$bvToast.toast('Si sono verificati degli errori nella messa in coda dell\'invio.', {
                                    title: 'Errore nella messa in coda dell\'invio',
                                    variant: 'danger',
                                    solid: true
                                });
                            }
                        })
                        .catch(({ response }) => {
                            console.log(response);
                        });

                    this.loading = false;
                }
            })
        },

        invioTesserati(table) {
            let html = '';

            html += '<div>';
            html += '<input class="swal-input" id="swal-idtesseratiforce" type="checkbox" value="1">';
            html += ' <label for="swal-idtesseratiforce">Invia tutti i tesserati anche quelli già inviati</label>';
            html += '</div>';

            Swal.fire({
                title: 'Conferma Invio Tesserati',
                text: "Sei sicuro di voler i Tesserati dei Sodalizi selezionati?",
                icon: 'success',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla',
                html: html,
                focusConfirm: false,
                preConfirm: () => {
                    let idannuality = this.idannualitysodalizi;
                    let force = document.getElementById('swal-idtesseratiforce').checked;

                    try {
                        if (idannuality) {
                            return { idannuality: idannuality, force: force };
                        }
                        else {
                            throw new Error('Selezionare un\'annualità');
                        }
                    }
                    catch (e) {
                        Swal.showValidationMessage(e);
                    }
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let values = [];
                    for (let i in this.$refs[table].items) {
                        if (this.$refs[table].items[i].checked) values.push({ idsportingclub: this.$refs[table].items[i].id, idannuality: this.$refs[table].items[i].annualityid });
                    }

                    if (values.length) {
                        let url = '/rsmonitoring/send-selected-enrolled-users';
                        let data = { values: values, idannuality: result.value.idannuality, force: result.value.force };

                        ApiService.post(url, data)
                            .then((response) => {
                                if (response.data.status === 'OK') {
                                    this.$bvToast.toast('Invio messo correttamente in coda', {
                                        title: 'Invio messo correttamente in coda',
                                        variant: 'success',
                                        solid: true
                                    });

                                    this.$refs.monitoraggio.refresh();
                                }

                                if (response.data.status === 'KO') {
                                    this.$bvToast.toast('Si sono verificati degli errori nella messa in coda dell\'invio.', {
                                        title: 'Errore nella messa in coda dell\'invio',
                                        variant: 'danger',
                                        solid: true
                                    });
                                }
                            })
                            .catch(({ response }) => {
                                console.log(response);
                            });
                    }
                    else {
                        Swal.fire({
                            title: 'Nessun Sodalizio selezionato',
                            text: "Per effettuare un'operazione selezionare almeno un Sodalizio",
                            icon: 'warning',
                            showConfirmButton: true,
                            confirmButtonText: 'Ok',
                        });
                    }

                    this.loading = false;
                }
            })
        },

        invioMassivoTesserati(table) {
            let html = '';

            html += '<div style="margin-top: 10px;">';
            html += '<input class="swal-input" id="swal-idtesseratiforce" type="checkbox" value="1">';
            html += ' <label for="swal-idtesseratiforce">Invia tutti i tesserati anche quelli già inviati</label>';
            html += '</div>';

            Swal.fire({
                title: 'Conferma Invio Massivo Tesserati',
                text: "Sei sicuro di voler i Tesserati massivamente?",
                icon: 'success',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla',
                html: html,
                focusConfirm: false,
                preConfirm: () => {
                    let idannuality = this.idannualitysodalizi;
                    let force = document.getElementById('swal-idtesseratiforce').checked;

                    try {
                        if (idannuality) {
                            return { idannuality: idannuality, force: force };
                        }
                        else {
                            throw new Error('Selezionare un\'annualità');
                        }
                    }
                    catch (e) {
                        Swal.showValidationMessage(e);
                    }
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let url = '/rsmonitoring/send-massive-enrolled-users';

                    let smartTable = this.$refs[table];
                    let filterQueryString = smartTable.getFiltersQueryString();
                    if (filterQueryString) url += '?' + filterQueryString;

                    let data = { type: table, idannuality: result.value.idannuality, force: result.value.force };

                    ApiService.post(url, data)
                        .then((response) => {
                            if (response.data.status === 'OK') {
                                this.$bvToast.toast('Invio messo correttamente in coda', {
                                    title: 'Invio messo correttamente in coda',
                                    variant: 'success',
                                    solid: true
                                });

                                this.$refs.monitoraggio.refresh();
                            }

                            if (response.data.status === 'KO') {
                                this.$bvToast.toast('Si sono verificati degli errori nella messa in coda dell\'invio.', {
                                    title: 'Errore nella messa in coda dell\'invio',
                                    variant: 'danger',
                                    solid: true
                                });
                            }
                        })
                        .catch(({ response }) => {
                            console.log(response);
                        });

                    this.loading = false;
                }
            })
        },

        invioDocumenti(table) {
            Swal.fire({
                title: 'Conferma Invio Documenti',
                text: "Sei sicuro di voler i Documenti dei Sodalizi selezionati?",
                icon: 'success',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let values = [];
                    for (let i in this.$refs[table].items) {
                        if (this.$refs[table].items[i].checked) values.push({ idsportingclub: this.$refs[table].items[i].id, idannuality: this.$refs[table].items[i].annualityid });
                    }

                    if (values.length) {
                        let url = '/rsmonitoring/send-selected-documents';
                        let data = { values: values };

                        ApiService.post(url, data)
                            .then((response) => {
                                if (response.data.status === 'OK') {
                                    this.$bvToast.toast('Invio messo correttamente in coda', {
                                        title: 'Invio messo correttamente in coda',
                                        variant: 'success',
                                        solid: true
                                    });

                                    this.$refs.monitoraggio.refresh();
                                }

                                if (response.data.status === 'KO') {
                                    this.$bvToast.toast('Si sono verificati degli errori nella messa in coda dell\'invio.', {
                                        title: 'Errore nella messa in coda dell\'invio',
                                        variant: 'danger',
                                        solid: true
                                    });
                                }
                            })
                            .catch(({ response }) => {
                                console.log(response);
                            });
                    }
                    else {
                        Swal.fire({
                            title: 'Nessun Sodalizio selezionato',
                            text: "Per effettuare un'operazione selezionare almeno un Sodalizio",
                            icon: 'warning',
                            showConfirmButton: true,
                            confirmButtonText: 'Ok',
                        });
                    }

                    this.loading = false;
                }
            })
        },

        invioMassivoDocumenti(table) {
            Swal.fire({
                title: 'Conferma Invio Massivo Documenti',
                text: "Sei sicuro di voler i Documenti massivamente?",
                icon: 'success',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let url = '/rsmonitoring/send-massive-documents';

                    let smartTable = this.$refs[table];
                    let filterQueryString = smartTable.getFiltersQueryString();
                    if (filterQueryString) url += '?' + filterQueryString;

                    let data = { type: table };

                    ApiService.post(url, data)
                        .then((response) => {
                            if (response.data.status === 'OK') {
                                this.$bvToast.toast('Invio messo correttamente in coda', {
                                    title: 'Invio messo correttamente in coda',
                                    variant: 'success',
                                    solid: true
                                });

                                this.$refs.monitoraggio.refresh();
                            }

                            if (response.data.status === 'KO') {
                                this.$bvToast.toast('Si sono verificati degli errori nella messa in coda dell\'invio.', {
                                    title: 'Errore nella messa in coda dell\'invio',
                                    variant: 'danger',
                                    solid: true
                                });
                            }
                        })
                        .catch(({ response }) => {
                            console.log(response);
                        });

                    this.loading = false;
                }
            })
        },

        invioPresidente(table) {
            Swal.fire({
                title: 'Conferma Invio Presidente',
                text: "Sei sicuro di voler il Presidente dei Sodalizi selezionati?",
                icon: 'success',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla',

                input: 'checkbox',
                inputValue: 0,
                inputPlaceholder: 'Invia con le date di ruolo originali?',

            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let values = [];
                    for (let i in this.$refs[table].items) {
                        if (this.$refs[table].items[i].checked) values.push({ idsportingclub: this.$refs[table].items[i].id, idannuality: this.$refs[table].items[i].annualityid });
                    }

                    if (values.length) {
                        let url = '/rsmonitoring/send-selected-president';
                        let data = { values: values, original_date: result.value };

                        ApiService.post(url, data)
                            .then((response) => {
                                if (response.data.status === 'OK') {
                                    this.$bvToast.toast('Invio messo correttamente in coda', {
                                        title: 'Invio messo correttamente in coda',
                                        variant: 'success',
                                        solid: true
                                    });

                                    this.$refs.monitoraggio.refresh();
                                }

                                if (response.data.status === 'KO') {
                                    this.$bvToast.toast('Si sono verificati degli errori nella messa in coda dell\'invio.', {
                                        title: 'Errore nella messa in coda dell\'invio',
                                        variant: 'danger',
                                        solid: true
                                    });
                                }
                            })
                            .catch(({ response }) => {
                                console.log(response);
                            });
                    }
                    else {
                        Swal.fire({
                            title: 'Nessun Sodalizio selezionato',
                            text: "Per effettuare un'operazione selezionare almeno un Sodalizio",
                            icon: 'warning',
                            showConfirmButton: true,
                            confirmButtonText: 'Ok',
                        });
                    }

                    this.loading = false;
                }
            })
        },

        invioMassivoPresidente(table) {
            Swal.fire({
                title: 'Conferma Invio Massivo Presidente',
                text: "Sei sicuro di voler inviare il Presidente massivamente?",
                icon: 'success',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let url = '/rsmonitoring/send-massive-president';

                    let smartTable = this.$refs[table];
                    let filterQueryString = smartTable.getFiltersQueryString();
                    if (filterQueryString) url += '?' + filterQueryString;

                    let data = { type: table };

                    ApiService.post(url, data)
                        .then((response) => {
                            if (response.data.status === 'OK') {
                                this.$bvToast.toast('Invio messo correttamente in coda', {
                                    title: 'Invio messo correttamente in coda',
                                    variant: 'success',
                                    solid: true
                                });

                                this.$refs.monitoraggio.refresh();
                            }

                            if (response.data.status === 'KO') {
                                this.$bvToast.toast('Si sono verificati degli errori nella messa in coda dell\'invio.', {
                                    title: 'Errore nella messa in coda dell\'invio',
                                    variant: 'danger',
                                    solid: true
                                });
                            }
                        })
                        .catch(({ response }) => {
                            console.log(response);
                        });

                    this.loading = false;
                }
            })
        },

        invioPresVice(table) {
            Swal.fire({
                title: 'Conferma Invio Presidente e Vicepresidente',
                text: "Sei sicuro di voler il Presidente e Vicepresidente dei Sodalizi selezionati?",
                icon: 'success',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla',

                input: 'checkbox',
                inputValue: 0,
                inputPlaceholder: 'Invia con le date di ruolo originali?',

            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let values = [];
                    for (let i in this.$refs[table].items) {
                        if (this.$refs[table].items[i].checked) values.push({ idsportingclub: this.$refs[table].items[i].id, idannuality: this.$refs[table].items[i].annualityid });
                    }

                    if (values.length) {
                        let url = '/rsmonitoring/send-selected-president-and-vicepresident';
                        let data = { values: values, original_date: result.value };

                        ApiService.post(url, data)
                            .then((response) => {
                                if (response.data.status === 'OK') {
                                    this.$bvToast.toast('Invio messo correttamente in coda', {
                                        title: 'Invio messo correttamente in coda',
                                        variant: 'success',
                                        solid: true
                                    });

                                    this.$refs.monitoraggio.refresh();
                                }

                                if (response.data.status === 'KO') {
                                    this.$bvToast.toast('Si sono verificati degli errori nella messa in coda dell\'invio.', {
                                        title: 'Errore nella messa in coda dell\'invio',
                                        variant: 'danger',
                                        solid: true
                                    });
                                }
                            })
                            .catch(({ response }) => {
                                console.log(response);
                            });
                    }
                    else {
                        Swal.fire({
                            title: 'Nessun Sodalizio selezionato',
                            text: "Per effettuare un'operazione selezionare almeno un Sodalizio",
                            icon: 'warning',
                            showConfirmButton: true,
                            confirmButtonText: 'Ok',
                        });
                    }

                    this.loading = false;
                }
            })
        },

        invioMassivoPresVice(table) {
            Swal.fire({
                title: 'Conferma Invio Massivo Presidente e Vicepresidente',
                text: "Sei sicuro di voler inviare il Presidente e Vicepresidente massivamente?",
                icon: 'success',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let url = '/rsmonitoring/send-massive-president-and-vicepresident';

                    let smartTable = this.$refs[table];
                    let filterQueryString = smartTable.getFiltersQueryString();
                    if (filterQueryString) url += '?' + filterQueryString;

                    let data = { type: table };

                    ApiService.post(url, data)
                        .then((response) => {
                            if (response.data.status === 'OK') {
                                this.$bvToast.toast('Invio messo correttamente in coda', {
                                    title: 'Invio messo correttamente in coda',
                                    variant: 'success',
                                    solid: true
                                });

                                this.$refs.monitoraggio.refresh();
                            }

                            if (response.data.status === 'KO') {
                                this.$bvToast.toast('Si sono verificati degli errori nella messa in coda dell\'invio.', {
                                    title: 'Errore nella messa in coda dell\'invio',
                                    variant: 'danger',
                                    solid: true
                                });
                            }
                        })
                        .catch(({ response }) => {
                            console.log(response);
                        });

                    this.loading = false;
                }
            })
        },

        invioFlusso(table) {
            Swal.fire({
                title: 'Conferma Invio Flusso',
                text: "Sei sicuro di voler il Flusso Completo dei Sodalizi selezionati?",
                icon: 'success',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let values = [];
                    for (let i in this.$refs[table].items) {
                        if (this.$refs[table].items[i].checked) values.push({ idsportingclub: this.$refs[table].items[i].id, idannuality: this.$refs[table].items[i].annualityid });
                    }

                    if (values.length) {
                        let url = '/rsmonitoring/send-selected-flux';
                        let data = { values: values };

                        ApiService.post(url, data)
                            .then((response) => {
                                if (response.data.status === 'OK') {
                                    this.$bvToast.toast('Invio messo correttamente in coda', {
                                        title: 'Invio messo correttamente in coda',
                                        variant: 'success',
                                        solid: true
                                    });

                                    this.$refs.monitoraggio.refresh();
                                }

                                if (response.data.status === 'KO') {
                                    this.$bvToast.toast('Si sono verificati degli errori nella messa in coda dell\'invio.', {
                                        title: 'Errore nella messa in coda dell\'invio',
                                        variant: 'danger',
                                        solid: true
                                    });
                                }
                            })
                            .catch(({ response }) => {
                                console.log(response);
                            });
                    }
                    else {
                        Swal.fire({
                            title: 'Nessun Sodalizio selezionato',
                            text: "Per effettuare un'operazione selezionare almeno un Sodalizio",
                            icon: 'warning',
                            showConfirmButton: true,
                            confirmButtonText: 'Ok',
                        });
                    }

                    this.loading = false;
                }
            })
        },

        invioMassivoFlusso(table) {
            Swal.fire({
                title: 'Conferma Invio Massivo Flusso',
                text: "Sei sicuro di voler il Flusso massivamente?",
                icon: 'success',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let url = '/rsmonitoring/send-massive-flux';

                    let smartTable = this.$refs[table];
                    let filterQueryString = smartTable.getFiltersQueryString();
                    if (filterQueryString) url += '?' + filterQueryString;

                    let data = { type: table };

                    ApiService.post(url, data)
                        .then((response) => {
                            if (response.data.status === 'OK') {
                                this.$bvToast.toast('Invio messo correttamente in coda', {
                                    title: 'Invio messo correttamente in coda',
                                    variant: 'success',
                                    solid: true
                                });

                                this.$refs.monitoraggio.refresh();
                            }

                            if (response.data.status === 'KO') {
                                this.$bvToast.toast('Si sono verificati degli errori nella messa in coda dell\'invio.', {
                                    title: 'Errore nella messa in coda dell\'invio',
                                    variant: 'danger',
                                    solid: true
                                });
                            }
                        })
                        .catch(({ response }) => {
                            console.log(response);
                        });

                    this.loading = false;
                }
            })
        },
    },
};
</script>
